import { useState, useEffect } from "react";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import { Link } from "react-router-dom";


import Twitter from '../../assets/twitter.png'
import linkedIn from '../../assets/linkedIn.png'
import insta from '../../assets/insta.png'
import logo from '../../assets/logopng.png'


const TWITTER_PAGE = 'https://twitter.com/devvpluss'
const INSTAGRAM_PAGE = 'https://www.instagram.com/devvplussolutions/'
const LINKEDIN_PAGE = ''
const FACEBOOK_PAGE = ''

const Footer = () => {
  const [table, setTable] = useState(false);
  const [table2, setTable2] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setTable(false)
      setTable2(false)

    }
  }, [isMobile]);


  return (
    <footer className="bg-gradient-to-l to-[#4921d9] from-[#6237d8] h-auto w-full bottom-0 left-0  text-white text-sm md:text-base font-primary relative mt-16 ">
      <div className="mx-auto max-w-[1120px] w-full px-5 md:px-10 pt-5 mt-6">
        <div className="border-solid border-b-[0.2px] border-b-white">
          <div className="mt-5 mb-4 flex md:flex md:justify-between md:items-start flex-col md:flex-row">
            <div className="hidden md:flex-col gap-2 md:flex">
              <div className='flex flex-row items-center mb-2'>
                <Link to='#'>
                  <img src={logo} alt='logo' className="object-contain relative h-[45px] w-[45px] flex" />
                </Link>
                <Link to='#' className='mt-3' >
                  <span className={`text-lg md:text-xl font-medium font-primary tracking-widest ml-[2px] ${'text-white'}`}>
                    Devvplus
                  </span>
                </Link>
              </div>
              <div className="flex">
                <Link to={INSTAGRAM_PAGE} >
                  <img src={insta} alt='logo' className="object-contain w-[45px] cursor-pointer relative group transform transition-transform hover:scale-110" />
                </Link>
                <Link to={LINKEDIN_PAGE}>
                  <img src={linkedIn} alt='logo' className="object-contain w-[45px] cursor-pointer relative group transform transition-transform hover:scale-110" />
                </Link>
                <Link to={TWITTER_PAGE}>
                  <img src={Twitter} alt='logo' className="object-contain w-[45px] cursor-pointer relative group transform transition-transform hover:scale-110" />
                </Link>
              </div>
            </div>
            <div>
              <div className="mb-4 font-semibold flex justify-between cursor-pointer" onClick={() => setTable(!table)}>
                <label className="cursor-pointer font-semibold">Company</label>
                <span className="inline md:hidden">
                  <KeyboardArrowDownTwoToneIcon
                    className={table
                      ? " transition-transform duration-500 rotate-180 text-base md:text-lg text-white"
                      : " transition-transform duration-500 rotate-0 inline text-base md:text-lg text-white"
                    } />
                </span>
              </div>
              <ul className={table ? 'md:flex  transition-height duration-500 ease-in-out  pb-3 w-full flex-col' : 'md:block hidden  transition-height duration-500  pb-3'}>
                {/* <Link to={'/how-we-work'} className='mt-3'>
                  <li className="mb-3 md:mb-2 cursor-pointer hover:text-blue-400">How we work</li>
                </Link>  */}
                <Link to={'/our-services'}>
                 <li className="mb-3 md:mb-2 cursor-pointer hover:text-blue-400">Our Services</li>
                </Link>
                <Link to={'/terms&conditions'} className='mt-3'>
                  <li className="mb-3 md:mb-2 cursor-pointer hover:text-blue-400">Terms and Conditions</li>
                </Link>
              </ul>
            </div>
            <div>
              <div className="mb-4 font-semibold flex justify-between cursor-pointer" onClick={() => setTable2(!table2)}>
                <label className="cursor-pointer font-semibold">Quick as</label>
                <span className="inline md:hidden">
                  <KeyboardArrowDownTwoToneIcon
                    className={table2
                      ? " transition-transform duration-500 rotate-180 text-base md:text-lg text-white"
                      : " transition-transform duration-500 rotate-0 inline text-base md:text-lg text-white"
                    } />
                </span>
              </div>
              <ul className={table2 ? 'md:flex  transition-height duration-500 ease-in-out  pb-3 w-full flex-col' : 'md:block hidden  transition-height duration-500  pb-3'}>
                <Link to={'testimonials'} >
                  <li className="mb-3 md:mb-2 cursor-pointer hover:text-blue-400">Testimonials</li>
                </Link>
                {/* <li className="mb-3 md:mb-2">Explore Mutual Funds</li>
								<li className="mb-3 md:mb-2">Fixed Deposit</li> */}
                {/* <li className="mb-3 md:mb-2">Credit Score</li> */}
                <Link to={'how-we-work'} >
                  <li className="mb-3 md:mb-2 cursor-pointer hover:text-blue-400">How we work</li>
                </Link>
                <Link to={'contact'} >
                 <li className="mb-3 md:mb-2 cursor-pointer hover:text-blue-400">Contact Us</li>
                 </Link>
              </ul>
            </div>


          </div>
        </div>
        <div className="mt-5 flex flex-col md:flex-row md:justify-between items-center pb-8">
          <div className="flex mb-5 md:hidden">
            <Link to={INSTAGRAM_PAGE} >
              <img src={insta} alt='logo' className="object-contain w-[45px] cursor-pointer relative group transform transition-transform hover:scale-110" />
            </Link>
            <Link to={LINKEDIN_PAGE}>
              <img src={linkedIn} alt='logo' className="object-contain w-[45px] cursor-pointer relative group transform transition-transform hover:scale-110" />
            </Link>
            {/* <a>
							<img src={telegram} alt='logo' className="object-contain w-[45px] cursor-pointer relative group transform transition-transform hover:scale-110" />
						</Link> */}
            <Link to={TWITTER_PAGE} >
              <img src={Twitter} alt='logo' className="object-contain w-[45px] cursor-pointer relative group transform transition-transform hover:scale-110" />
            </Link>
          </div>
          <span className="order-2 md:order-1 w-[280px] md:w-auto md:mx-0  mx-auto text-center md:text-start">©Copyright 2023 Devvplus Solutions Pvt. Ltd. All Rights Reserved</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
