import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";

import banner from "../../assets/banner.png"

const OurMission = () => {
  const mainControls = useAnimation();
  const ref = useRef(null)
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible")
    }
  }, [isInView])

  return (
    <article className='mt-11 bg-gradient-to-tl to-[#b2a6df] from-[#ffff]'>
      <motion.div className='max-w-[1120px]  mx-7 md:mx-8 lg:mx-9 xl:mx-auto  flex flex-col md:flex-row  pt-16 pb-11 gap-6 md:gap-0' variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
      }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        <div className='flex-1 order-2 md:order-1'>
          <h1 className='text-2xl sm:text-3xl md:text-5xl text-[#42337b] mb-2 sm:mb-3 md:mb-4 font-bold' >Our Mission</h1>
          <h2 className='text-slate-600 text-xl md:text-3xl mb-3 md:mb-5 font-medium'>Devvplus the digital world - one software product at a time</h2>
          <p className='text-lg md:text-xl leading-[28px] md:leading-[30px]'>More than mere code, at Devvplus Solutions, we embody a philosophy of holistic digital solutions. Our dedicated team of software developers collaborates seamlessly with engineers and business managers, adhering to rigorous quality assurance processes to deliver software solutions that are both fail-proof and future-proof. Recognized consistently for our quality and innovation, Devvplus Solutions stands endorsed by various industry leaders and professionals.</p>
        </div>
        <div className='flex-1 flex justify-center md:justify-end order-1 md:order-2'>
          <img src={banner} alt="Example" className='h-[350px] md:h-[420px] w-[350px] md:w-[420px] object-contain' ref={ref} />
        </div>
      </motion.div>
    </article>
  )
}

export default OurMission
