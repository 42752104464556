import React from "react";
import HeaderTestimonial from "./headerTestimonial/HeaderTestimonial";
import Review from "./review/Review";
import TestimonialContent from "./contentTestimonial/TestimonialContent";
import ContactTestimonial from "./contactTestimonial/ContactTestimonial";

const Testimonial = () => {
  return (
    <>
      <HeaderTestimonial />
      <TestimonialContent />
      <Review />
      <ContactTestimonial />
    </>
  );
};

export default Testimonial;
