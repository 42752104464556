import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";

import superheroes from "../../assets/superheroes.png"
import OurMission from '../OurMission/OurMission'
import Features from '../Features/Features'


const AboutUs = () => {
  const mainControls = useAnimation();
  const ref = useRef(null)
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible")
    }
  }, [isInView])


  return (
    <section id="section" className='flex flex-col' >
      <motion.div className='mt-5 flex max-w-[1120px]  mx-7 md:mx-8 lg:mx-9 xl:mx-auto  flex-col' variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
      }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        <div className=' justify-center flex'>
          <img src={superheroes} alt="Example" className='h-[300px] md:h-[420px] w-[350px] md:w-[520px] object-contain' />
        </div>
        <h1 className='flex justify-center text-2xl sm:text-4xl md:text-6xl font-bold text-[#42337b] text-center' ref={ref}>League of Extraordinary Developers</h1>
        <p className='text-center flex text-lg sm:text-xl md:text-2xl mt-5 md:mt-7 leading-[30px]  sm:leading-[40px] md:leading-[45px]   justify-center'>Devvplus Solutions is a leading software development company, providing end-to-end services. Catering to startups and MSMEs alike, we excel in MVPs, enterprise apps, APIs, and innovative SaaS solutions. Our commitment to quality shines through Git management, code reviews, and seamless CI/CD releases. Automated testing ensures reliability. Meticulous documentation and vigilant performance monitoring enhance user experiences. Beyond coding, we engineer enduring solutions, making us the go-to for innovation and quality. As tech evolves, we evolve, delivering ongoing value to our esteemed partners.
        </p>
      </motion.div>
      <OurMission />
      <Features />
    </section>
  )
}

export default AboutUs
