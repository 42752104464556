import React from 'react'
import Testimonial from '../components/Testimonial/Testimonial'

const Testimonials = () => {
  return (
    <>
      <Testimonial/>
    </>
  )
}

export default Testimonials
