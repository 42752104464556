import React, { useState, useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import Engage from "../../assets/Engage.png";
import wireframe from "../../assets/wireframe.png";
import UIdesign from "../../assets/UIdesign.png";
import UIdevelopment from "../../assets/UIdevelopment.png";
import webdevelopment from "../../assets/webdevelopment.png";
import testing from "../../assets/testing.webp";
import delivery from "../../assets/delivery.png";
import maintenance from "../../assets/maintenance.png";
import backgroundimg from "../../assets/backgroundimg.png";

function Howwework() {
  const mainControls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  const [activeIndex, setActiveIndex] = useState(0);
  const workData = [
    {
      headicon: "",
      heading: "Engage",
      content1: `Entrusting us with your website initiates a meticulous process. We delve into your business, believing in the power of thorough research. Our team engages in extensive Q&A sessions, setting the foundation for subsequent stages.`,
      content2: `We value every query, ensuring complete satisfaction before moving forward. Only when our team receives satisfactory responses, do we mark this step as completed. Transitioning to the next phase follows.`,
      image: Engage,
      bgc: "bg-gradient-to-tl to-[#680285] from-[#ffff]",
      scrollcolor: "#A63289",
    },
    {
      headicon: "",
      heading: "Wireframe",
      content1: `Meticulous planning is key in any endeavor, be it warfare or website development. A blueprint serves as a crucial tool, translating ideas into a tangible format understood by all parties. Wireframes represent the skeletal structure, designating element placement, outlining their behavior during user interactions.`,
      content2: `Clients access the wireframe via our 'demo URL' for review and feedback. Upon approval, we proceed to the next phase in development.`,
      image: wireframe,
      bgc: "bg-gradient-to-tl to-[#008080] from-[#99CCCC]",
      scrollcolor: "#99CCCC",
    },
    {
      headicon: "",
      heading: "UI Design",
      content1: `Once the initial structure is set, our UI designers bring the wireframe to life, specializing in diverse design techniques. We embrace Flat UI design and create unique, innovative layouts for your ideas. With remote demo access, we keep you engaged in the design process.`,
      content2: `In the final steps of UI design, your satisfaction is paramount. We'll refine every detail until you're delighted with the results.`,
      bgc: "bg-gradient-to-tl to-[#A63289] from-[#ffff]",
      image: UIdesign,
      scrollcolor: "#A63289",
    },
    {
      headicon: "",
      heading: "UI Development",
      content1: `In this phase, UI-crafted graphics come alive. UX developers work their magic, making buttons functional, animations fluid, and icons interactive. Their focus is seamless device adaptation via responsive HTML.`,
      content2: `We also prioritize optimizing loading speed for swift access. You'll receive an updated hosted 'demo URL' for feedback and potential changes.`,
      image: UIdevelopment,
      bgc: "bg-gradient-to-tl to-[#3d5f7c]  from-[#a7c3cf]",
      scrollcolor: "#a7c3cf",
    },
    {
      headicon: "",
      heading: "Web Development",
      content1: `Software installation, be it professional or personal, owes to skilled developers. Collaboration among experts in system, programming, and application development yields exceptional products. Stages ensure top-notch user experiences, innovative products, and operational efficiency.`,
      content2: `Every software, with its distinct purpose, receives our transparent approach. We provide regular updates and adapt based on business needs.`,
      image: webdevelopment,
      bgc: "bg-gradient-to-tl to-[#4921d9] from-[#6237d8]",
      scrollcolor: "#4921d9",
    },
    {
      headicon: "",
      heading: "Testing & Debugging",
      content1: `Our seasoned testers conduct comprehensive evaluations, testing performance across devices, from desktops to smartphones. We ensure compatibility with major browsers. Quality extends to W3C code standards, SEO-friendly content, and optimized speed. Devvplus Solution maintains a rigorous testing regimen.`,
      content2: `In cases requiring multiple testing iterations for perfection, our celebrations are even more jubilant. Congratulatory gestures and a strong sense of accomplishment permeate our team.`,
      image: testing,
      bgc: "bg-gradient-to-tl to-[#6237d8] from-[#ffff]",
      scrollcolor: "#6237d8",
    },
    {
      headicon: "",
      heading: "Delivery & Support",
      content1: `After successful product testing, your website is ready for delivery. Choose to handle file upload independently or let us manage it for a seamless go-live. Devvplus Solution provides a 30-day free support period post-project. Our team offers expert-crafted video tutorials for efficient website management.`,
      content2: `Upon website delivery, we prioritize your satisfaction and remain available for any future enhancements or updates.`,
      image: delivery,
      bgc: "bg-gradient-to-tl to-[#1167b1] from-[#ffff]",
      scrollcolor: "#1167b1",
    },
    {
      headicon: "",
      heading: "Maintenance",
      content1: `We understand the challenges associated with website updates and ongoing maintenance. To ease this burden, we've got it covered. Our skilled tech team is here to assist you throughout the entire process, from design to coding. We offer a range of budget-friendly maintenance packages, including hourly, monthly, and yearly options, designed to cater to your specific needs.`,
      content2: `Once you've chosen a suitable plan, communicating your requirements is simple. You can send us instructions via email or opt for voice/video calls. Think of us as your dedicated maintenance partner, available for as long as you need. Whether it's developing a brand-new website or making improvements to an existing one, Devvplus Solution is your trusted source for comprehensive web solutions.`,
      image: maintenance,
      bgc: "bg-gradient-to-tl to-[#02b2d3] from-[#370e62]",
      scrollcolor: "#02b2d3",
    },
  ];
  const bgColor = [
    {
      title: "Engage",
      code: "#680285",
      filterClass: activeIndex === 0 ? "drop-shadow-lg" : "",
    },
    {
      title: "Wireframe",
      code: "#96E5CD",
      filterClass: activeIndex === 1 ? "drop-shadow-lg" : "",
    },
    {
      title: "UI Design",
      code: "#A63289",
      filterClass: activeIndex === 2 ? "drop-shadow-lg" : "",
    },
    {
      title: "UI Development",
      code: "#a7c3cf",
      filterClass: activeIndex === 3 ? "drop-shadow-lg" : "",
    },
    {
      title: "Development",
      code: "#4921d9",
      filterClass: activeIndex === 4 ? "drop-shadow-lg" : "",
    },
    {
      title: "Testing & Debugging",
      code: "#6237d8",
      filterClass: activeIndex === 5 ? "drop-shadow-lg" : "",
    },
    {
      title: "Delivery & Support",
      code: "#1167b1",
      filterClass: activeIndex === 6 ? "drop-shadow-lg" : "",
    },
    {
      title: "Maintenance",
      code: "#02b2d3",
      filterClass: activeIndex === 7 ? "drop-shadow-lg" : "",
    },
  ];

  const [work, setWork] = useState(workData[0]);
  const [bgcolor, setBgcolor] = useState(0);

  const handleButtonClick = (index, event) => {
    event.preventDefault();
    setBgcolor(index);
    setWork(workData[index]);
    setActiveIndex(index);
  };

  return (
    <>
      <div className="flex m-4 justify-between flex-col ">
        <div className="-z-10 flex justify-center object-cover items-center h-[18vh] sm:h-[40vh] md:h-[60vh] lg:h-[60vh] xl:h-[60vh] relative">
          <div className="-z-20 absolute flex object-cover ">
            <img src={backgroundimg} alt="" />
          </div>
          <div className="-z-10 mt-11 flex justify-center items-center text-2xl sm:text-4xl md:text-6xl font-bold text-[#42337b] text-center" ref={ref}>
            We Ensure to Construct bit by bit
          </div>
        </div>
        <div className="mt-11 flex justify-evenly bg-[#F5F5F5]">
          <nav
            className="max-w-[1120px] mx-7 md:mx-8 lg:mx-9 xl:mx-auto whitespace-nowrap flex flex-col md:flex-row custom-scrollbar"
            style={{
              display: "flex",
              overflowX: "auto",
            }}
          >
            <style>
              {`
                .custom-scrollbar::-webkit-scrollbar {
                 width: 50px;
                 height: 6px;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb {
                 background-color: ${work.scrollcolor};
                 border-radius: 6px;
                 height: 6px;
                }
                .custom-scrollbar::-webkit-scrollbar-track {
                 background-color: #ffffff;
                }
              `}
            </style>
            <ul className="flex m-2 justify-between">
              {bgColor.map((color, index) => (
                <li className="flex justify-center min-h-[60px]" key={index}>
                  <span 
                  
                    className="text-center flex items-center p-3 cursor-pointer"
                    key={index}
                    onClick={(event) => handleButtonClick(index, event)}
                    style={{
                      boxShadow: bgcolor === index ? "0 0 11px rgba(0, 0, 0, 0.3)" : "none",
                      borderRadius: "10px",
                      border: bgcolor === index ? `2px solid ${color.code}` : "none",
                      backgroundColor: bgcolor === index ? "white" : "",
                    }}
                  >
                    <span
                      className="min-w-[25px] mr-[15px] ml-[4px]"
                      style={{
                        boxShadow:
                          bgcolor === index ? "0 0 9px rgba(0, 0, 0, 0.3)" : "none",
                        border: `2px solid ${color.code}`,
                        borderRadius: "50%",
                      }}
                    >
                      {index + 1}
                    </span>
                    <div className="">{color.title}</div>
                  </span>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className={`flex text-white ${work.bgc}  flex-col md:flex-row `}>
          <motion.div
            className="mt-5 flex max-w-[1120px] mx-7 md:mx-8 lg:mx-9 xl:mx-auto flex-col"
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
          >
            <div className="max-w-[1120px] flex flex-col md:flex-row pt-16 pb-11 gap-6 md:gap-0" ref={ref}>
              <div className=" m-4 md:w-1/2">
                <div className="text-4xl font-bold sm:text-3xl md:text-5xl mb-2 sm:mb-3 md:mb-4">
                  {work.heading}
                </div>
                <div className="text-lg md:text-xl leading-[28px] md:leading-[30px]">
                  {work.content1}
                </div>
                <div className="text-lg md:text-xl leading-[28px] md:leading-[30px]">
                  {work.content2}
                </div>
              </div>
              <div className="flex-1 flex justify-center items-center ">
                <img src={work.image} className="mx-4 max-w-[360px] h-[200px] sm:h-[400px] lg:h-[350px] sm:w-[320px] md:w-[768px] lg:w-[905px]" alt="" />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Howwework;
