import { TypeAnimation } from 'react-type-animation';
import { Link } from "react-router-dom";
import banner from "../../assets/banner.png"

const Banner = () => {

  return (
    <section id="banner" className="bg-gradient-to-l to-[#4921d9] from-[#6237d8] h-[770px] xm:h-[750px] sm:h-[780px] md:h-[650px] lg:h-[500px] text-white md:font" >
      <div className='max-w-[1120px]  mx-7 md:mx-8 lg:mx-9 xl:mx-auto flex flex-col md:flex-row' >
        <div className="flex-1 flex gap-3 sm:gap-4  md:gap-6 flex-col mt-[50px] md:mt-[60px] order-2 md:order-1">
          <h1 className='text-xl sm:text-2xl md:text-4xl  font-semibold sm:font-bold  md:font-extrabold leading-[35px] sm:leading-[40px] md:leading-[50px] text-center sm:text-start'>
            The text artists for digital products
          </h1>
          <h2 className=' text-lg sm:text-xl md:text-2xl leading-[28px] sm:leading-[32px] md:leading-[35px] text-center sm:text-start h-[82px] md:h-[92px] lg:h-[58px]'>
            <TypeAnimation
              sequence={[
                'We develop remarkable software products & digital footprint for startups to enterprises',
                500,
                'We develop remarkable software products & digital footprint for startups to enterprises',
                500
              ]}
              speed={70}
              deletionSpeed={70}
              repeat={Infinity}
            />
          </h2>
          <h2 className='my-3 sm:my-4 text-lg sm:text-xl font-semibold text-center sm:text-start'>
            Excited 🤩! Talk to our experts.
          </h2>
          <div className='flex justify-center sm:justify-start'>
          <Link to="/contact" className='cursor-pointer'>
            <button className='border p-2 sm:p-3 text-base sm:text-lg rounded-lg sm:rounded-xl w-48 font-semibold hover:cursor-pointer flex justify-center hover:bg-[#ffff] hover:text-[#4921d9]'>Let's start a Project</button>
            </Link>
          </div>
        </div>
        <div className="flex md:flex-1 order-1 md:order-2 justify-center  md:justify-end  mt-9 md:mt-10 "> <img src={banner} alt="Example" className='h-[350px] md:h-[520px] lg:h-[420px] w-[350px] md:w-[420px] object-contain' /></div>
      </div>
    </section>
  );
};

export default Banner;
