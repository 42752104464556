import React from "react";
import DontJust from "./DontJust";
import TestimonialCard from "./TestimonialCard";

const TestimonialContent = () => {
  return (
    <div className=" bg-[#F3F1F9] my-4 md:my-8 lg:my-14 py-2 md:py-4">
      <div className="flex flex-col justify-center md:flex-row flex-wrap mx-2 md:mx-6 lg:mx-12 p-5 md:p-10 lg:p-15">
        <DontJust />
        <TestimonialCard />
      </div>
    </div>
  );
};

export default TestimonialContent;
