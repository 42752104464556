import React from 'react'
import Banner from '../components/Banner/Banner'
import AboutUs from '../components/AboutUs/AboutUs'

const About = () => {
  return (
    <>
      <Banner/>
      <AboutUs/>
    </>
  )
}

export default About
