import React from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";



const Cards = ({ title, description, icon }) => {
  const mainControls = useAnimation();
  const ref = useRef(null)
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible")
    }
  }, [isInView])

  return (
    <motion.article className='border-gray-300 p-5 rounded-xl  h-auto sm:w-full md:w-[80%] lg:w-[45%] border-2 transform  transition-transform duration-700 ease-in hover:shadow-custom' variants={{
      hidden: { opacity: 0, x: 23 },
      visible: { opacity: 1, x: 0 }
    }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.4, delay: 0.25 }}
      ref={ref}
    >
      <img src={icon} alt='' className='h-[50px] sm:h-[55px] md:h-[65px]' />
      <h1 className='text-lg sm:text-2xl md:text-2xl font-bold my-3 md:my-3'>{title}</h1>
      <p className=''>{description}</p>
    </motion.article>
  )
}

export default Cards
