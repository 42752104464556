import React from 'react';
import ReactMarkdown from 'react-markdown';
import termsContent from './MarkdownTerms';

function Conditions() {
    return (
        <div>
            <ReactMarkdown className=" mx-8 mt-8 prose lg:prose-xl max-w-none" >
          
            
                {termsContent}
            </ReactMarkdown>
        </div>
    );
}

export default Conditions;