import React from "react";

const ReviewCard = () => {
  const content = [
    {
      id: 1,
      review: `We have chosen Devvplus Solutions after a long research. We have cross checked capabilities and reviews of many vendors and finally awarded our projects to Devvplus. I can proudly say that my decision was correct. They have given value to my business and were sincere and caring towards the project throughout it’s lifecycle.`,
      imag: "	https://webreinvent.com/images/client-rahul.webp",
      name: "Vivek Agnihotri",
      experience: "Good",
    },
    {
      id: 2,
      review: `From the first day of our contact, we found every member of this team is very friendly and helpful. I never felt that I am being down because of my less technical knowledge. They not only make web applications for you but they train you very well to use them as well. By coming in contact of these guys, I started taking more educate decisions.`,
      imag: "https://webreinvent.com/images/author-avatar-defult.svg",
      name: "Mohd. Azam",
      experience: "Best",
    },
    {
      id: 3,
      review: `Many, many thanks for your fast and efficient delivery of work. We have been really, really impressed with the level of customer service Web reinvent has provided to date. Please pass our thanks and congratulations to your team involved in IFLIIP project.`,
      imag: "	https://webreinvent.com/images/clients-pawan-shah.webp",
      name: "Freddics",
      experience: "Good",
    },
  ];
  return (
    <>
      {content.map((item, index) => (
        <div
          key={index}
          className="w-66 md:w-72 lg:w-80 h-[350px] md:h-[450px] bg-[white] my-1  shadow-lg rounded-xl"
        >
          <div className="mx-2 md:mx-3 lg:mx-6 pt-2 md:pt-3 lg:pt-6">
            <p className=" overflow-hidden h-[270px] md:h-80 text-lg md:text-lg pl-2 pt-2 md:pl-0 md:pt-0">
              {item.review}
            </p>
          </div>
          <div className="flex justify-center pr-7 my-2 md:my-4 lg:my-6 mx-4 md:mx-7 lg:mx-10">
            <div className=" flex justify-center items-center w-16 h-14 mr-2">
              <img
                src={item.imag}
                className=" rounded-full h-14 md:h-16 lg:h-18 w-14 md:w-16 lg:w-18"
                alt=""
              />
            </div>

            <div className="text-center">
              <div className="font-bold text-lg">
                {item.name}
              </div>
              <div className="">
                <span className="text-[#578187] mr-1 font-bold">
                  Experience
                </span>
                <span className="text-[#16A286] font-bold">
                  {item.experience}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ReviewCard;
