import React from "react";

const HeaderTestimonial = () => {
  return (
    <>
      <div className="flex justify-center mt-7 md:mt-14">
        <img src="https://webreinvent.com/images/product-hero.webp" alt=""></img>
      </div>

      <div className="flex flex-col justify-center md:flex-row  w-[720] my-4">
        <span className=" text-[#312852] text-center text-4xl md:text-4xl lg:text-6xl mr-2 font-bold">
          Our hearts Beats for
        </span>
        <span className=" text-[#312852] text-center text-3xl md:text-4xl lg:text-6xl">
          Appereation
        </span>
      </div>

      <div className="flex justify-center text-[#846685]  md:text-xl leading-[28px] md:leading-[30px]">
        <p className=" w-[70%] text-sm md:text-xl text-center">
          It feels great when you get admiration for your efforts and hard work.
          It is always a proud moment for us when our clients applaud us for our
          work and give their valuable feedback.
        </p>
      </div>
    </>
  );
};

export default HeaderTestimonial;
