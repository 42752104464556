import React from 'react';
import CardSlider from './CardSlider';
import Plans from './PlansandPackage.js';

function Services() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center ">
      <CardSlider />
      <Plans/>
    </div>
  );
}

export default Services;
