import React, {useRef, useState, useEffect} from "react";
import ReviewCard from "./ReviewCard";
import Confetti from "react-confetti";
import { motion, useInView, useAnimation } from "framer-motion";

const Review = () => {

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    
  const [confettiActive, setConfettiActive] = useState(false);

  
    useEffect(() => {
      if (isInView) {
        mainControls.start("visible");
      }
    }, [isInView, mainControls]);
    useEffect(() => {
      if (isInView) {
        
        
        setTimeout(() => {
          setConfettiActive(true);
        }, 200);
      }
    }, [isInView]);
  return (
    <>
   
        <div className="relative" ref={ref} >
        {confettiActive && (
        <Confetti
          numberOfPieces={350}
          gravity={0.6}
          recycle={false}
          className="w-[99%]"
        />
      )}
       
      <div className="bg-[#efecf6] py-2 md:py-6 lg:py-10" ref={ref}>
     
        <div className="text-[#312852] text-center font-bold text-3xl md:text-4xl lg:text-6xl py-2 md:py-4 lg:py-8">
          We Made Them Happy.
        </div>
        <div className="text-[#846685] text-sm md:text-xl lg:text-2xl text-center justify-center my-2 lg:my-4 p-3">
          We are here to please you with our website creating skill!
          Let's achieve the dream together.
        </div>
        <motion.div
            className=""
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
          >
        <div className="flex justify-center flex-wrap gap-8 my-4 mx-4 md:mx-16 lg:mx-40" ref={ref}>

          <ReviewCard />
          
          </div>
          </motion.div>
        </div>
      
      </div>
      
     
    </>
  );
};

export default Review;
