import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import Conditions from './components/Terms/Conditions';
import About from './pages/About';
import OurWork from './pages/OurWork';
import Testimonials from './pages/Testimonials';
import OurServices from './pages/OurServices';


function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" exact element={<About />} />
        <Route path="/home" exact element={<About />} />
        <Route path="/how-we-work" element={<OurWork />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms&conditions" element={<Conditions/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
