import React from "react";

const TestimonialCard = () => {
  const content = [
    {
      id: 1,
      review: `I want to shout out that the team has done such a great job in getting the site up today.`,
      imag: "https://webreinvent.com/images/clients-jeff-crystal.webp",
      name: "Paul coops",
      experience: "Good",
    },
    {
      id: 2,
      review: `Your whole team did an outstanding job throughout the project and handled the implementation professionally and politely.`,
      imag: "	https://webreinvent.com/images/client-daniel-bates.webp",
      name: "Jacob Hellswing",
      experience: "Good",
    },
    {
      id: 3,
      review: `I knew when I was asking for such a short turnaround that it is a titanic task, but the team understood my reasons and responded in the most supportive manner.`,
      imag: "https://webreinvent.com/images/clients-zack-rinkins.webp",
      name: "James Andrew",
      experience: "Excellent",
    },
  ];

  return (
    <>
      {content.map((item, index) => (
        <div
          key={index}
          className="flex-col rounded-xl shadow-lg my-5 bg-[#ffffff] md:mx-4 lg:mx-6 w-66 md:w-72 lg:w-84 h-72 lg:h-84 lg:h-96"
        >
          <p
            key={index}
            className="text-gray-700 overflow-y-auto text-lg pl-3 pt-2 md:pt-0  md:pl-0 md:text-lg w-[90%] md:w-[90%] h-[70%] mx-2  md:mx-4 lg:mx-8 my-2 md:my-3 lg:my-6 md:pr-8  mb-2"
          >
            {item.review}
          </p>
          <div className="flex justify-center">
            <div className=" flex justify-center items-center w-14 h-14">
              <img
                src={item.imag}
                alt=""
                className="h-10 md:h-12 lg:h-14 w-10 md:w-12 lg:w-14"
              />
            </div>

            <div className="text-center mx-2">
              <div className=" font-bold text-[14px] md:text-sm">
                {item.name}
              </div>
              <div className="">
                <span className="text-[#181f21] mr-1 text-[14px] md:text-sm">
                  Experience
                </span>
                <span className="text-[#F7B036] text-[14px] md:text-sm">
                  {item.experience}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TestimonialCard;
