import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";

import strength from "../../assets/strength.jpg"
import Card from '../Card/Card'

const Features = () => {
  const mainControls = useAnimation();
  const ref = useRef(null)
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible")
    }
  }, [isInView])

  return (
    <>
      <article className='flex max-w-[1120px]  mx-7 md:mx-8 lg:mx-9 xl:mx-auto  flex-col md:flex-row mt-7 md:mt-9'>
        <div className='flex flex-col '>
          <motion.article variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 }
          }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
          >
            <h1 className='text-2xl sm:text-4xl md:text-6xl font-bold   text-[#42337b]' ref={ref}>Our own super strengths</h1>
            <p className='mt-5 md:mt-7 text-lg md:text-xl leading-[30px] md:leading-[34px]'>The Devvplus team isn't ordinary. They combine regular thinking with incredible skills for perfect projects. They're like hidden superheroes, pretending to be normal to amaze you.</p>
          </motion.article>
          <img src={strength} alt="strength" className='h-[350px] md:h-[520px] lg:h-[420px] w-[350px] md:w-[420px] object-contain opacity-10 mt-10 flex ' />
        </div>
        <Card />
      </article>
    </>
  )
}

export default Features
