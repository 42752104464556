import React from "react";

const DontJust = () => {
  return (
    <div className=" flex flex-col my-5 mx-2 md:mx-10 lg:w-60 lg:h-[322px]">
      <div className=" flex flex-col ">
        <span className="text-[#312852] text-center md:text-left text-2xl md:text-2xl lg:text-4xl font-bold">
          Don't Just Take Our Words For It...
        </span>
        <p className="text-[#846685] text-center md:text-left text:sm md:text-xl lg:text-2xl ">
          Here are a few testimonials found from Super-Fans
        </p>
      </div>
    </div>
  );
};

export default DontJust;
