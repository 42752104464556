import React from 'react'

import SingleCard from "../SingleCard/SingleCard"
import customer from "../../assets/customer.png"
import quality from "../../assets/quality.png"
import transparency from "../../assets/transparency.png"
import notepad from "../../assets/notepad.png"

const details = [
  {
    title: "Quality deliverables",
    description: "If there is one thing we never compromise on that is our quality. We believe in delivering our best and that is exactly what we do.",
    icon: quality
  },
  {
    title: "Expertise",
    description: "What makes us so good is the fact we love doing what we do. Every individual at Devvplus is hired for the driving passion they have for their work.",
    icon: notepad
  },
  {
    title: "Customer oriented approach",
    description: "Businesses are defined by how well their customers think of them. We are what we are today because of our dedicated services that our clients appreciate.",
    icon: customer
  },
  {
    title: "Honesty and transparency",
    description: "We don’t take undue advantage of client’s ignorance of technological elements and always give them honest support. We also keep a transparent system as far as the prices for services are concerned.",
    icon: transparency
  }
];


const Card = () => {

  return (
    <div className=' mt-8 flex flex-col md:flex-row gap-5 md:flex-wrap justify-end lg:w-[2616px]'>
      {
        details.map((detail) => <SingleCard title={detail.title} description={detail.description} icon={detail.icon} key={detail.title} />)
      }

    </div>
  )
}

export default Card
