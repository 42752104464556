import React from 'react'
import Howwework from '../components/HowweWork/Howwework'
import Review from '../components/Testimonial/review/Review'

const OurWork = () => {
  return (
    <>
      <Howwework/>
      <Review/>
    </>
  )
}

export default OurWork
