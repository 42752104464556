import React, { useState } from "react";
import standard5 from "../../assets/standard5.png";
import premium3 from "../../assets/premium3.png";
import professional5 from "../../assets/professional5.png";
import bg5 from "../../assets/bg5.png";

const cardData = [
  {
    id: 1,
    text: "Standard",
    mainimage: standard5,
    content1: `Unlock the ideal plan that suits your vision and ensure your Digital presence Now! `,
    content2: `₹ 5999`,
    barcolor: "#4921d9",
    featuresavail: [
      { name: "Customize Design" },
      { name: "Mobile Responsive" },
      { name: "Dynamic website" },
      { name: "Domain" },
      { name: "Hosting" },
      { name: "SSL" },
      { name: "Slider" },
      { name: "1 Contact Form" },
      { name: "Social Profile Link" },
      { name: "Deliver in 14 days" },
      { name: "Free 24/7 Support for 2 months" },
      { name: "Annual Renewal ₹2500" },
    ],
    featuresnotavail: [
      { name: "Testimonials" },
      { name: "Business Email id (Webmail)" },
      { name: "WhatsApp Integration" },
      { name: "Chat Feature" },
      { name: "Click to Call/Email" },
    ],
  },
  {
    id: 2,
    text: "Premium",
    mainimage: premium3,
    content1: `Unlock the ideal plan that suits your vision and ensure your Digital presence Now!`,
    content2: `₹ 8999`,
    barcolor: "#4921d9",
    featuresavail: [
      { name: "Customize Design" },
      { name: "Mobile Responsive" },
      { name: "Dynamic website" },
      { name: "Domain" },
      { name: "Slider" },
      { name: "Hosting" },
      { name: "SSL" },
      { name: "Social Profile Link" },
      { name: "Contact Form" },
      { name: "1 Business Email id (Webmail)" },
      { name: "Chat Feature" },
      { name: "Click to Call/Email" },
      { name: "Deliver in 21 days" },
      { name: "free 24/7 Support for 4 months" },
      { name: "Annual Renewal ₹4000" },
    ],
    featuresnotavail: [
      { name: "Testimonials" },
      { name: "WhatsApp Integration" },
      { name: "Chat Feature" },
    ],
  },
  {
    id: 3,
    text: "Professional",
    mainimage: professional5,
    content1: `Unlock the ideal plan that suits your vision and ensure your Digital presence Now! `,
    content2: `₹ 11999`,
    barcolor: "#4921d9",
    featuresavail: [
      { name: "Customize Design" },
      { name: "Mobile Responsive" },
      { name: "Dynamic website" },
      { name: "Domain" },
      { name: "Slider" },
      { name: "Hosting" },
      { name: "SSL" },
      { name: "Testimonials" },
      { name: "Contact Form" },
      { name: "2 Business Email id (Webmail)" },
      { name: "WhatsApp Integration" },
      { name: "Chat Feature" },
      { name: "Social Profile Link" },
      { name: "Click to Call/Email" },
      { name: "Deliver in 28 days" },
      { name: "free 24/7 Support for 6 months" },
      { name: "Annual Renewal ₹5000" },
    ],
    featuresnotavail: [],
  },
];

const Plans = () => {
  const [startIndex] = useState(0);
  const [collapsedStates, setCollapsedStates] = useState(
    Array(cardData.length).fill(false)
  );
  const [hovered, setHovered] = useState(false);
  const handleHover = (cardid) => {
    setHovered((prevHovered) => ({
      ...prevHovered,
      [cardid]: true,
    }));
  };
  const handleLeave = (cardid) => {
    setHovered((prevHovered) => ({
      ...prevHovered,
      [cardid]: false,
    }));
  };
  const toggleCollapse = (cardIndex) => {
    setCollapsedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[cardIndex] = !newStates[cardIndex];
      return newStates;
    });
  };

  return (
    <>
      <div className="flex flex-col items-center text-center space-y-4 mt-3">
        <div className="flex flex-col justify-center items-center text-center ">
          <div className="my-4 flex justify- text-2xl sm:text-4xl md:text-6xl font-bold text-[#42337b] text-center">
            Best Plans for You
          </div>
          <div className="flex flex-col items-center justify-center text-[#846685] md:text-xl leading-[28px] md:leading-[30px]">
            <p className="w-[70%] flex text-sm md:text-xl text-center">
              Unveil the perfect Plans for your dream website, where excellence
              meets your unique requirements.
            </p>
            <p className="flex justify-center text-sm md:text-xl text-center">
              "Your vision, our expertise!"
            </p>
          </div>
          <div className="text-center max-w-[200px]" />
        </div>
        <div className="flex  mt-6">
          <div className="z=0 absolute flex ">
            {" "}
            <img src={bg5} alt="" />{" "}
          </div>
          <div className="flex flex-col items-start justify-center md:space-x-5 md:flex-row sm:flex-col mx-4 ">
            {cardData.slice(startIndex, startIndex + 3).map((card) => (
              <div
                key={card.id}
                className="flex items-center mt-4 bg-[#ffff] border-2 rounded-lg w-[280px] sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 transform transition-transform duration-300 hover:shadow-xl"
                style={{
                  borderColor: hovered[card.id] ? "white" : card.barcolor,
                  borderWidth: hovered[card.id] ? "0" : "2px",
                  borderRadius: hovered[card.id] ? "0s" : "8px",
                }}
                onMouseEnter={() => handleHover(card.id)}
                onMouseLeave={() => handleLeave(card.id)}
              >
                <div
                  className={`flex z-0 text-white px-[3px] py-20 ${hovered[card.id] ? "hidden" : "flex"
                    }`}
                  style={{ backgroundColor: card.barcolor }}
                />
                <div className="min-h-[32px] w-full object-cover">
                  <div
                    className={`rounded-t-lg mx:0 flex flex-col items-center 
                    }`}
                    style={{
                      color: hovered[card.id] ? "white" : card.barcolor,
                      backgroundColor: hovered[card.id] ? card.barcolor : "",
                      borderColor: hovered[card.id] ? "white" : card.barcolor,
                    }}
                  >
                    <div
                      className={`transition-colors duration-300 ease-in-out flex items-center m-0 text-2xl sm:text-3xl md:text-3xl py-2 sm:py-3 md:py-4 font-bold  ${hovered[card.id] ? "text-white" : ""
                        }`}
                    >
                      {card.text}
                    </div>
                  </div>
                  <div className="mt-1 flex justify-center">
                    <img src={card.mainimage} alt="" />
                  </div>
                  <div className="h-[70%] flex flex-col items-center justify-between">
                    <div className="ml-2 mb-2 px-2 text-[#818589]">
                      {card.content1}
                    </div>
                    <div className=" ml-2 mb-2 px-2 text-2xl sm:text-3xl md:text-3xl font-bold text-[#4921d9]">
                      {card.content2}
                    </div>
                    <div className="flex flex-col justify-center ml-4 ">
                      <div
                        className={`mt-4 flex flex-col ${collapsedStates[card.id] ? "block" : "hidden"
                          }`}
                      >
                        {card.featuresavail.map((feature1, index) => (
                          <div
                            key={index}
                            className="flex items-center text-left mb-2"
                          >
                            <span className="mr-2">
                              <span
                                className="py-[1px] px-[4px] min-w-[30px] min-h-[25px] mr-[15px] bg-[#00B200] text-white font-bold"
                                style={{
                                  borderRadius: "50%",
                                }}
                              >
                                &#x2713;
                              </span>
                            </span>
                            <span>{feature1.name}</span>
                          </div>
                        ))}
                      </div>
                      <div
                        className={`flex flex-col  ${collapsedStates[card.id] ? "block" : "hidden"
                          }`}
                      >
                        {card.featuresnotavail.map((feature2, index) => (
                          <div
                            key={index}
                            className="flex items-center text-left mb-2"
                          >
                            <span className="mr-2">
                              <span
                                className="pt-[1px] pb-[3px] px-[6px] min-w-[30px] mr-[15px] bg-[#DC3545] text-white "
                                style={{
                                  borderRadius: "50%",
                                }}
                              >
                                x
                              </span>
                            </span>
                            <span>{feature2.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={`w-full rounded-b-lg`}
                      style={{
                        color: hovered[card.id] ? "white" : card.barcolor,
                        backgroundColor: hovered[card.id] ? card.barcolor : "",
                      }}
                    >
                      <button
                        onClick={() => toggleCollapse(card.id)}
                        className={`w-[70%] py-3 my-4 border-[1px] rounded-lg font-semibold`}
                        style={{
                          color: hovered[card.id] ? card.barcolor : "white",
                          borderColor: hovered[card.id]
                            ? "white"
                            : card.barcolor,
                          backgroundColor: hovered[card.id]
                            ? "white"
                            : card.barcolor,
                        }}
                      >
                        {collapsedStates[card.id]
                          ? "Hide Features"
                          : "Show full Features"}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`z-0 flex text-white px-[3px] py-20  ${hovered[card.id] ? "hidden" : "flex"
                    }`}
                  style={{ backgroundColor: card.barcolor }}
                />{" "}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
