import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_76wwwbk', 'template_50uuc43', form.current, 'gKg3mYnf1eALfO7we')
      .then((result) => {
        e.target.reset();
        setIsSubmitted(true); // Set the flag to true after successful submission
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <>
      {!isSubmitted ? ( // Render the form if not submitted
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="max-w-xl mx-auto">
            <div className="text-center">
              <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl ">
                Contact us
              </h1>
              <p className="mt-1 text-gray-600 ">
                We'd love to talk about how we can help you.
              </p>
            </div>
          </div>

          <div className="mt-12 max-w-lg mx-auto">
            <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 ">
              <h2 className="mb-8 text-xl md:text-2xl font-semibold text-gray-800 ">
                Fill in the form
              </h2>

              <form ref={form} onSubmit={sendEmail}>
                <div className="grid gap-4 lg:gap-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                    <div>
                      <label
                        htmlFor="hs-firstname-contacts-1"
                        className="block text-base md:text-lg text-gray-700 font-medium "
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="user_firstname"
                        className="py-2 px-3 block w-full rounded-md text-base md:text-lg border border-[#6237d8] focus:border-blue-700 focus:border-2 focus:outline-none"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="hs-lastname-contacts-1"
                        className="block text-base md:text-lg text-gray-700 font-medium "
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="user_lastname"
                        id="hs-lastname-contacts-1"
                        className="py-2 px-3 block w-full rounded-md text-base md:text-lg border border-[#6237d8] focus:border-blue-700  focus:border-2  focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                    <div>
                      <label
                        htmlFor="hs-email-contacts-1"
                        className="block text-base md:text-lg text-gray-700 font-medium "
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="user_email"
                        id="hs-email-contacts-1"
                        autoComplete="email"
                        className="py-2 px-3 block w-full rounded-md text-base md:text-lg border border-[#6237d8] focus:border-blue-700  focus:border-2 focus:outline-none"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="hs-phone-number-1"
                        className="block text-base md:text-lg text-gray-700 font-medium "
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        name="user_phone"
                        id="hs-phone-number-1"
                        className="py-2 px-3 block w-full rounded-md text-base md:text-lg border border-[#6237d8] focus:border-blue-700  focus:border-2 focus:outline-none"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="hs-about-contacts-1"
                      className="block text-base md:text-lg text-gray-700 font-medium "
                    >
                      Your Query
                    </label>
                    <textarea
                      id="hs-about-contacts-1"
                      name="message"
                      rows="4"
                      className="py-2 px-3 block w-full  rounded-md text-base md:text-lg border border-[#6237d8] focus:border-blue-700  focus:border-2 focus:outline-none"
                    ></textarea>
                  </div>
                </div>

                <div className="mt-6 grid">
                  <button
                    type="submit"
                    value="Send"
                    className="inline-flex justify-center items-center gap-x-3 text-center bg-[#6237d8] hover:bg-blue-700 border border-transparent text-base md:text-lg lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 "
                  >
                    Send inquiry
                  </button>
                </div>

                <div className="mt-3 text-center">
                  <p className="text-sm text-gray-500">
                    We'll get back to you in 24 hours.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : ( // Render the confirmation message if submitted
        <div className='flex justify-center items-center flex-col p-6 mx-[20px] my-[90px] gap-2 md:gap-4'>
          <div className='text-2xl md:text-4xl lg:text-6xl font-extrabold  xm:mt-14'>
            Thanks for Contacting Us!
          </div>
          <div className='text-lg md:text-2xl lg:text-3xl font-bold'>
            We will get back to you within 24 hours.
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
