import React from "react";
import { Link } from "react-router-dom";

const ContactTestimonial = () => {
  return (
    <div className="my-[5px] md:my-[50px] lg:my-[100px]">
      <div className="text-[#312852] text-center font-bold text-2xl md:text-4xl lg:text-6xl py-2  md:py-4 mt-2 md:mt-8 lg:mt-16">
        Excited? Let's Talk..
      </div>
      <div className="text-[#846685] text-center text-sm md:text-xl lg:text-2xl py-2 md:py-3 lg:py-6">
        You’re Our First Priority. Rest All Can Wait.
      </div>

      <div className="my-[15px] md:my-[40px] lg:my-[70px]">
        <div className="flex justify-center mt-2 align-bottom">
          <p className="text-[#846685] flex items-end text-sm pb-[-2px] md:text-xl lg:text-2xl ">
            WRITE TO US
          </p>
          <Link to="/contact" className='cursor-pointer'>
            <img
              className="mb-1 h-[40px] w-[40px] md:h-[60px] md:w-[60px] cursor-pointer"
              src="https://webreinvent.com/images/write-to-us.svg"
              alt=""
            ></img>
          </Link>
        </div>
        <div className="hover:text-[#846685] text-black font-bold text-sm md:text-xl lg:text-2xl  text-center">
          <Link to="/contact" className='cursor-pointer'>
            devvplussolutions@gmail.com
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactTestimonial;
