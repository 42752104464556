import React, { useState, useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import nedd from "../../assets/nedd.webp";
import vid11 from "../../assets/vid11.mp4";
import needbg6 from "../../assets/needbg6.png";
import matrimonial from "../../assets/matrimonial.jpg";
import corporate from "../../assets/corporate.jpg";
import business from "../../assets/business.jpg";
import portfolio from "../../assets/portfolio.jpg";
import marketing from "../../assets/marketing.jpg";
import ecommerce from "../../assets/ecommerce.jpg";
import realestate from "../../assets/realestate.jpg";
import guesthouse from "../../assets/guesthouse.jpeg";
import restaurant from "../../assets/restaurant.jpg";
import educational from "../../assets/educational.jpg";
import health from "../../assets/health.jpg";
import blog from "../../assets/blog.jpg";
import manufacturer from "../../assets/manufacturer.jpeg";
import traveling from "../../assets/traveling.jpg";
import { Link } from "react-router-dom";


const cardData = [
  {
    id: 1,
    text: "Restaurant",
    mainimage: restaurant,
    content: `Elevate your online presence, showcasing menus, prebookings, attracting more diners. Enhance restaurant's digital identity with us.`,
  },
  {
    id: 2,
    text: "E-commerce",
    mainimage: ecommerce,
    content: `Bring the customer at your store! Let's fashion your digital success together!`,
  },
  {
    id: 3,
    text: "Health",
    mainimage: health,
    content: `Unlock the potential of your health and fitness business with our expert web development services.`,
  },
  {
    id: 4,
    text: "Education",
    mainimage: educational,
    content: `Empower education through our specialized web development service. Let's digitalise the success.`,
  },
  {
    id: 5,
    text: "Matrimonial",
    mainimage: matrimonial,
    content: `Crafting love stories online. Trust us to create the perfect matrimonial website for your matchmaking needs.`,
  },
  {
    id: 6,
    text: "Marketing",
    mainimage: marketing,
    content: `Elevate your marketing business with our tailored web solutions. Partner with us for digital excellence today.`,
  },
  {
    id: 7,
    text: "Travel Booking",
    mainimage: traveling,
    content: `Elevate your travel booking business online. We specialize in crafting websites that make booking journeys seamless.`,
  },
  {
    id: 8,
    text: "Corporate",
    mainimage: corporate,
    content: `Boost your corporate presence online. We excel in creating websites that represent your business effectively.`,
  },

  {
    id: 9,
    text: "Guest House",
    mainimage: guesthouse,
    content: `"Elevate your guest house's online presence. Make it easy for guests to find and book your accommodations. Elevate your guest house's online presence today.`,
  },

  {
    id: 10,
    text: "Real Estate",
    mainimage: realestate,
    content: `Elevate your real estate business online. We specialize in crafting websites that showcase properties effectively.`,
  },
  {
    id: 11,
    text: "Other Business",
    mainimage: business,
    content: `We craft websites for all types of businesses. From small startups to established brands, we design and develop digital solutions as per your needs.`,
  },
  {
    id: 12,
    text: "Blog",
    mainimage: blog,
    content: `We create websites for personal blogs. Share your stories, thoughts, and ideas with the world through a customized and user-friendly online platform designed just for you.`,
  },
  {
    id: 13,
    text: "Personal Portfolio",
    mainimage: portfolio,
    content: `Showcase your unique story. Let us create your personalized portfolio website, making your online presence shine`,
  },
  {
    id: 14,
    text: "Manufacturer",
    mainimage: manufacturer,
    content: `We specialize in making websites for contract manufacturers. Showcase your capabilities and attract clients with a professional online presence tailored to your industry.`,
  },
];

const CardSlider = () => {
  const mainControls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  const [startIndex, setStartIndex] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(3);
  const nextSlide = () => {
    if (startIndex < cardData.length - cardsPerPage) {
      setStartIndex((prevIndex) => prevIndex + 1);
    }
  };
  const prevSlide = () => {
    if (startIndex > 0) {
      setStartIndex((prevIndex) => prevIndex - 1);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 560) {
        setCardsPerPage(1);
      } else if (window.innerWidth >= 560 && window.innerWidth <= 1024) {
        setCardsPerPage(2);
      } else {
        setCardsPerPage(3);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const preloadImages = cardData.map((card) => {
    const image = new Image();
    image.src = card.mainimage;
    return image;
    
  });
  return (

    <div className="flex flex-col items-center text-center space-y-4">
      <div className="flex flex-col justify-center items-center text-center ">
        <div className=" sm:mb-11 md:mb-11 lg:mt-11 2xl:mt-11 flex flex-col justify-center items-center align-center">
          <div className="flex absolute justify-center -z-10">
            <video
              className="flex justify-center opacity-[100%] w-[100vw] h-[auto]"
              autoPlay
              muted
              loop
            >
              <source src={vid11} type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col justify-center items-center h-full mt-2 md:mt-8 lg:mt-16">
            <div className="md:text-3xl lg:tex-5xl md:font-bold text-[#ffff] text-center ">
              Transform your idea into
            </div>
            <div
              className=" my-2 text-2xl sm:text-3xl md:text-7xl font-bold text-[#ffff] text-center lg:text-9xl"
              style={{ textShadow: "2px 2px 5px #0000ff" }}
            >
              Digital Reality
            </div>
            <div className="  md:text-3xl lg:text-5xl md:font-bold text-[#ffff] text-center">
              With Our
            </div>
            <div
              className=" text-2xl sm:text-4xl md:text-6xl lg:text-8xl font-bold text-[#ffff] text-center"
              style={{ textShadow: "2px 2px 5px #0000ff" }}
            >
              Services
            </div>
          </div>
        </div>
        <div
          className="flex flex-col w-[] justify-center items-center bg-center bg-no-repeat bg-cover pb-10 mt-5px  sm:mt-[10px] md:mt-6 lg:mt-[70px] xl:mt-[80px] 2xl:mt-[300px] "
          style={{ backgroundImage: `url(${needbg6})` }}
        >
          <div className="flex justify-center object-cover bg-cover mt-16 xm:mt-[98px] sm:mt-20 md:mt-[80px] lg:mt-[84px] xl:mt-28 2xl:mt-0 3xl:mt-10">
            <div className="  flex flex-col justify-center items-center">
              <div className="my-4 flex justify- text-2xl sm:text-4xl md:text-6xl font-bold text-[#42337b] text-center">
                What Do You Need?
              </div>
              <div className="flex justify-center text-[#846685] md:text-xl leading-[28px] md:leading-[30px]">
                <p className="flex w-[70%] text-sm md:text-xl text-center">
                  From Elegance to Functionality, We Create Stunning Websites
                  for various Domains. Select Your Digital Domain, Let Us Craft
                  Its Unique Online Presence.".
                </p>
              </div>
              <div className="text-center max-w-[200px] lg:mt-6">
                <img
                  src={nedd}
                  alt=""
                  className=" w-[60px] sm:w-[125px] md:w-[150px] lg:w-[200px]"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center py-8 ">
      
            <motion.div
              className=""
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.7, delay: 0.5 }}
            >
              <div
                className="flex items-center justify-center space-x-4"
                ref={ref}
              >
                <button
                  className={`rounded-lg h-[50%] bg-[#4921d9] bg-opacity-[60%] text-white px-2 py-6 font-bold  
                  ${
                    startIndex > 0
                      ? "opacity-100"
                      : "opacity-0 pointer-events-none"
                  }`}
                  onClick={prevSlide}
                >
                  &lt;
                </button>

                {cardData
                  .slice(startIndex, startIndex + cardsPerPage)
                  .map((card) => (
                    <div
                      key={card.id}
                      className="rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 transform transition-transform duration-300  hover:scale-105 mx-auto hover:shadow-xl"
                    >
                      <div className="h-[320px] object-cover">
                        <div className="w-full h-full">
                          <img
                            src={card.mainimage}
                            alt=""
                            className="w-full h-full object-cover opacity-[100%] rounded-lg"
                          />
                        </div>
                        <div className=" h-[100%] mx:0 absolute top-0 left-0 right-0 z-5 flex flex-col items-center ">
                          <div className="w-full justify-center flex items-center text-[#cfb53b] m-0 text-2xl sm:text-3xl md:text-3xl py-2 sm:py-3 md:py-4 font-bold rounded-t-lg ">
                            {card.text}
                          </div>
                          <div className="h-[100%] my-2 flex flex-col items-center justify-between">
                            <div className=" ml-2 py-4 px-2 text-white">
                              {card.content}
                            </div>
                            <Link to="/contact" className='cursor-pointer w-[70%]'>
                            <button className="w-[100%] py-3 mb-2 text-white border-[1px] rounded-lg border-[#ffff] font-semibold">
                              Contact Us
                            </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <button
                  className={`rounded-lg h-[20%] bg-[#4921d9] bg-opacity-[60%] text-white px-2 py-6  font-bold"
                ${
                  startIndex < cardData.length - cardsPerPage
                    ? "opacity-100"
                    : "opacity-0 pointer-events-none"
                }`}
                  onClick={nextSlide}
                >
                  &gt;
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSlider;
