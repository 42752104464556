const termsContent = `

## TERMS & CONDITIONS 


1.	Devvplus Solutions (hereinafter referred to as “Devvplus”) is a partnership firm that aims to provide web development services which cater to the diverse needs of businesses and organizations. This agreement lists the terms & conditions governing usage of this website and our services, including upgrades thereto and materials made available therein. By signing to this agreement, you consent to follow the entirety of this agreement.

2.	The clauses written herein, along with any mobile license agreement, and other posted guidelines within our Website, is collectively called as “Terms of use.”  This agreement between client and Devvplus Solutions supersedes all other agreements, representations, warranties and understandings with respect to our Website and the subject matter contained herein.


3.	The terms “we”, “us”, “our” refer to Devvplus and  “you”, “users”, “buyers” or “service provides” refers to users who visit the Devvplus website (“Website”) and/or contribute content to Devvplus via the Website, email, telephone, or otherwise. All text, information, graphics, audio, video, and data offered through our Website are collectively known as our "Content".

4.	We may amend our Terms of Use at any time without prior notice and your continued access to or use of the Services signifies your acceptance of the updated or modified terms.

5.	If you are entering into Terms of Use on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to the Terms. In that case, the terms “you” or “your” shall also refer to such entity, and its affiliates, as applicable. If you do not have such authority, or you do not accept all of these Terms, do not use the Services or provide information to Devvplus solutions.

### DISCLAIMER 

6.	While due diligence has been exercised to ensure that the information published on this website is adequate and reliable, no representation or warranties is made by Devvplus as to its accuracy, completeness, or fitness for any purpose. You must verify the veracity of every information before using the Services.

7.	Devvplus makes no claims with respect to any third party, such as the companies reviewed on the services.  Further, it does not endorse any company, vendor, service or product depicted in the services and does not advise users to select any particular company on the basis of its reviews or ratings. Reviews on the services consist of the opinions of the reviewers and should not be construed as statements of fact. We do not provide any express warranties or representations of quality, accuracy, or reliability of user content. Your purchase or use of, and the quality and performance of, any products or services offered by third parties reviewed on the services is at your own discretion and risk. 

### ELIGIBILITY

8.	You must be 18 years of age or older in order to use the Services and Website or submit reviews or other User Content.

### CONFIDENTIALITY 

9.	Terms of use abide by a Non – Disclosure agreement where it is agreed that we would not disclose any information that the user has shared with us as a necessary part of using our services. 

### CONDITIONS FOR USE OF THE SERVICES 

10.	As a condition of your use of the Services, you agree to do the following:
a.	Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, tax laws, and regulatory requirements;
b.	Provide accurate information to us and update it as necessary; and
c.	Review and comply with notices sent by us concerning the Services

### LEGAL COMPLIANCE

11.	You agree to comply with all applicable domestic and international laws, statutes, ordinances, and regulations regarding your use of our Website. We reserve the right to investigate complaints or reported violations of our Legal Terms and to take any action we deem appropriate, including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to your profile, email addresses, usage history, posted materials, IP addresses and traffic information, as allowed under our Privacy Policy. Please verify the veracity of all information on your own before undertaking any alliance.

### LINKS TO OTHER WEBSITES

12.	Our Website may contain links to third party websites. These links are provided solely as a convenience to you. By linking to these websites, we do not create or have an affiliation with, or sponsor such third party websites. The inclusion of links within our Website does not constitute any endorsement, guarantee, warranty, or recommendation of such third party websites. We have no control over the legal documents and privacy practices of third party websites; as such, you access any such third party websites at your own risk.

### PRIVACY POLICY

13.	By accepting this agreement, you consent to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy.

##### a.	Information collected by us

14.	Under our policy, dual type of information is collected :- “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit, and preferences that are generated based on the data you submit and number of clicks. Personal Information includes only the data (i.e. your name, email and phone number) which you submit by filling the form under ‘Contact Us’ section of the website [‘the form’].

##### b.	Information collected via Technology

15.	To activate the Service you do not need to submit any Personal Information other than information submitted to us by filling the form. To use the Service thereafter, you do not need to submit further Personal Information. However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. 

16.	We track this information using cookies. They are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information including visitors' preferences, and the pages on the website that the visitor accessed or visited. For instance, we use cookies to help us remember and understand your preferences based on previous or current site activity, which enables us to provide you with improved services. Further, cookies help us to compile data about site traffic and interactions which enables us to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.

17.	For instance, Google Analytics is an analytics service which helps Google to report and track website traffic and service usage, This data is then shared with other services of Google. This data may be used by Google in its own advertising networks ads. To gather more data regarding Google’s privacy practice, please visit Privacy and Terms page of Google https://policies.google .com/privacy?hl=e
Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users.
18.	We may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser.

19.	You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
Note: Below ways are just a reference and may change according to version and time.
•	Chrome: Chrome Browser menu -> Settings -> Privacy and Security -> Cookies and other site data.
•	Internet Explorer: Internet Explorer Browser menu -> Settings -> Cookies and site permissions.
•	Mozilla Firefox: Mozilla Firefox Browser menu -> Settings -> Privacy and Security -> Cookies and Site data.
Note: If you disable cookies, some features might get disabled and some of our services may not function properly.

##### c.	Information you provide us by filling the form

20.	In addition to the information provided automatically by your browser when you visit the Website, you provide us your information such as name, email, phone etc. by filling in the form under ‘Contact Us’ section. By filling, you are authorizing us to collect, store, and use your email address in accordance with this Privacy Policy.

##### d.	How we use and share information

i.	Personal Information
21.	Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent, or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications, who are provided access to user’s email address for purposes of sending emails from us. 

22.	Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy. Thus, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions, solicit feedback from users and provide technical support.


ii.	Non-Personal Information
23.	In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers, and other third parties at our discretion.

24.	In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used.

##### e.	How We Protect Information

25.	We protect your information from potential security breaches by implementing certain technological security measures, including encryption, firewalls, and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered, or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.

##### f.	Your Rights Regarding the use of your personal information

26.	You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that you do not wish to receive marketing communications from us in the “Settings” section of the Site. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out in the Settings section of the Site, we may continue to send you administrative emails, including, for example, periodic updates to our Privacy Policy.

### INTELLECTUAL PROPERTY RIGHTS

27.	The contents of these pages are Copyright © 2023 Devvplus Solutions. We are the owner or the licensee of all intellectual property rights in our Website, and in the images and other material published on it (“the Content”). The Content is protected by copyright laws and treaties around the world. All such rights are reserved.
28.	You may print off one copy, and may download extracts of any page(s) from our Website for your personal reference and you may draw the attention of others within your organization to material posted on our Website.
29.	You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. Our status (and that of any identified contributors) such as the authors of Content on our website must always be acknowledged.
30.	You must not use any part of the materials on our Website for commercial purposes without obtaining a license to do so from us or our licensors. If you print off, copy or download any part of our website in breach of these terms of use, your right to use our website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.

### INDEMNIFICATION

31.	You agree to indemnify, defend, and hold Devvplus harmless from and against any and all third-party claims, liabilities, demands, settlements, damages, losses, and expenses of any kind (including, but not limited to, reasonable attorneys’ fees and costs) arising out or relating in any way to (1) your failure to comply with these Terms, including, without limitation, your submission of User Content or (2) your use of the Services or any activity in which you engage on or through Dev+.

### PAYMENT POLICY

32.	Amounts written in the estimation are excluding GST (Goods & Services Tax) or any other taxes applicable.

33.	Any work or task which is not written in this estimation will be charged additional on hourly basis e.g. creating email accounts, uploading files, updating images or content, adding new products, creating video tutorials, technical documentation, git repo backup etc.

34.	Documents/files like database structure, raw files like PSD, HTML, user flow diagram etc will be charged additional.

35.	We expect payment as per the due date of each invoice.

36.	In any case, if the due date of an invoice is not written, then consider the due date as immediate and if an invoice is overdue for more than 10 days, then it reserves the right to stop working without any further communication or may impose a penalty.

37.	If Devvplus doesn't get the final payment within 30 days from completion of the project or in case of no response from the client for 30 days, it reserves the right to sell the entire work to a third party to recover the expense even if we are under NDA contract.

38.	If the client wishes to cancel the project at any point in time during the process, then they shall remain liable to pay for the hours invested or the work completed and shall be invoiced accordingly.

39.	Billable Hours: Hour will be billable for the time invested in code/documentation/guideline study or review, meetings, phone calls, tasks, chats etc.

40.	If any third party tool/extension/plugin/application is used or required in the system/website/application, and if the tool/extension/plugin/application is a paid version (not free), then the license will be purchased by the client and then given to us for its implementation and we can't determine such instance at the time of estimation.

41.	In case of no response received for 30 days regarding payment or approval or tasks then reserve the right to close the project without any refund.

42.	Our charges are only for our services and it does not include the cost of any paid tool or other service.

43.	If an understanding of code is required, charges will incur.

44.	The images that we will use on the site will be dummy stock images and we recommend you to buy these images so you hold the copyright to use these images. Our quote does not include the pricing of these images.

45.	No documentation of the project is included in the work of scope if it's not written in the estimation items. Only wireframes, UI Designs, HTML, files and database are deliverables if written.

46.	Once the web app is delivered, we are not responsible for the backup.

47.	Our software does not support any version of Internet Explorer (IE).

### CANCELLATION POLICY

48.	We follow a transparent and no confusion cancellation policy. If the cancellation request is made within 12 hours of placing the order and execution of the project has not started already then only it will be considered. Further, we will not be held responsible for any third party services, such as hosting, web development, content writing etc.

### REFUND POLICY

49.	Due to the nature of its services, it does not guarantee any refunds upon cancellation. It will not be held responsible for any refund claims thereof and does not make any guarantees on the basis of traffic & rankings etc.

### GOVERNING LAW AND JURISDICTION

50.	The terms and conditions of this Policy shall be governed by Indian Laws, and the courts in Lucknow, shall only have exclusive jurisdiction to resolve/ adjudicate upon any disputes.


`
;

export default termsContent;